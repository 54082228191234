// Mixin Button variable
$config: (
  max-width: 24.9rem,
  width: 100%,
  padding: 12px 10px 24px,
  border-radius: 0.8rem,
  background-color: transparent,
  color: #fff,
  font-size: 1.6rem,
  line-height: 150%,
  margin: 0.8rem 0.8rem 0.8rem 0,
  transition: 0.5s all,
);

$fs: 1rem;

$button: (
  background-color: #1e2d66,
);

// Color Variable
$primary-color: #1373e7;
$secondary-color: #1e2d66;
$quote_bg: #374785;
$quote-white-bg: #F7F9FD;
$success-color: #d4f8d3;
$grey-color: #e9eaf0;
$reaction_back: #F7F9FD;
$grey-color-dark: #E9EAEA;
$white-color: #ffffff;
$title-color: #282828;
$black-color: #000000;
$tab-head: #222222;
$tab-text: #4f4f4f;
$menu-text: #e1ebf9;
$border-color: #e0e0e0;
$grey-text: #767676;
$table-hover: #f3f8ff;
$blue-color: #0f5cb9;
$separtor-color: #b9bed0;
$success-msg-color: #298700;
$success-bg: #fafff8;
$dark-bg: #121b3d;
$form-input-border: #dde0e8;
$form-input-color: #1d1d1d;
$form-input-active-bg: #e7f1fd;
$pair-bg:#C2DEFF;
$form-lable-color: #333333;
$light-gray: #828282;
$red-required: #d64444;
$error-msg: #eb5757;
$light-blue: #f2f7fd;
$light-blue-line: #D2DBE6;
$tag-blue: #e7f0fc;
$blue-bg: #e7f1fdab;
$darl-blue-bg: #17224d;
$light-yellow: #fff0bb;
$light-sky: #d8e3ff;
$red-border: #ff0000;
$search-input: #efefef;
$msg-date: #4d4a4f;
$project_bg: #d6e2f5;
$disabled-clr: #f0f0f0;
$lighten-blue: #f3f8fe;
$lighten-dark-blue: #0e56ad;
$reject_mesg: #d8000c;
$dark_gray: #8a93a2;
$dark_gray_full: #dce0e6;
$gray-100: #f4f8fd;
$dark-blue: $secondary-color;
$Light-blue-clr: $primary-color;
$light-black: $form-lable-color;
$success-msg-green: $success-msg-color;
$red-clr-required: $red-required;
$red-clr-border: $red-border;
$success-clr-bg: $success-color;
$grey-100: $grey-color;
$light-clr-yellow: $light-yellow;
$light-clr-sky: $light-sky;
$transparent: transparent;

// Media Query
$breakpoints: (
  four_extra_large: 1699px,
  three_extra_large: 1440px,
  two_fifth_extra_large: 1380px,
  one_extra_large: 1280px,
  two_extra_large: 1170px,
  extra_large: 1023px,
  large: 989px,
  medium: 767px,
  small: 579px,
  md_small: 480px,
  extra_small: 392px,
);

$global-styles: (
  font-size: 10px,
  scrollbar-color: #0e56ad #e7f1fd,
);

// Animation
$animation-configurations: (
  popup_animate: (
    animation: popupRoll 0.6s ease-in-out forwards,
    keyframes: (
      0%: (
        opacity: 0,
        transition: all 0.6s ease-in-out,
      ),
      100%: (
        opacity: 1,
        transition: all 0.6s ease-in-out,
      ),
    ),
  ),
  radiobtn_animate: (
    animation: updown 0.6s backwards,
    keyframes: (
      0%: (
        border: 0.1rem solid transparent,
        transition: all 0.1s ease-in-out,
      ),
      100%: (
        border: 0.1rem solid $primary-color,
        transition: all 1s ease-in-out,
      ),
    ),
  ),
  partner_modal_animate: (
    animation: partnerModal 0.7s ease-in-out forwards,
    keyframes: (
      0%: (
        opacity: 0,
        transition: all 0.7s ease-in-out,
      ),
      100%: (
        opacity: 1,
        transition: all 0.7s ease-in-out,
      ),
    ),
  ),
);

// tooltip
$tooltip-properties: (
  color: $white-color,
  font-size: 1.4rem,
  font-weight: 400,
  line-height: 140%,
  padding: 0.8rem 1rem,
  border-radius: 0.4rem,
  background: $dark-bg,
  display: none,
  white-space: nowrap,
  top: -3.7rem,
  left: -2rem,
  gap: 1rem,
);

// full-username
$full-username-properties: (
  font-size: 1.4rem,
  font-weight: 400,
  line-height: 140%,
  padding: 0.8rem 1rem,
  border-radius: 0.4rem,
  gap: 1rem,
  top: -3.7rem,
  left: 0,
  right: auto,
  z-index: 9,
  display: none,
);

$ipopup-properties: (
  position: fixed,
  padding: 1rem,
  left: 0,
  top: 0,
  overflow: auto,
  z-index: 104,
  background-color: rgba(0, 0, 0, 0.4),
  transition: all 0.6s ease-in-out,
  opacity: 1,
);

// i_popup_content
$ipopup-content-properties: (
  background-color: $white-color,
  padding: 3.2rem,
  margin: auto,
  border-radius: 2rem,
  box-shadow: 0rem 0rem 3rem 0rem rgba(116, 116, 116, 0.15),
  top: 50%,
  left: 50%,
  transform: translate(-50%, -50%),
  max-width: 70rem,
  overflow-y: auto,
  overflow-x: hidden,
);

$default-li-styles: (
  font-size: 1.8rem,
  line-height: 140%,
  border-radius: 0.8rem 0.8rem 0.2rem 0.2rem,
  color: $tab-text,
  background: transparent,
  padding: 0.8rem 1.5rem,
  min-height: 4.7rem,
  min-width: 21.2rem,
  white-space: nowrap,
  cursor: pointer,
  border-bottom: 0.4rem solid transparent,
);

$default-shape-styles: (
  width: 4rem,
  height: 4rem,
  border: 0.1rem solid $border-color,
  border-radius: 5rem,
  background-color: $white-color,
);

$default-table-styles: (
  width: 100%,
  min-width: 120rem,
  border-collapse: separate,
  background: $dark-blue,
  border-radius: 0.8rem,
  border-spacing: 0,
);

$myStyles: (
  table-layout: fixed,
  position: relative,
  background: $dark-blue,
);

$bgcolor-configurations: (
  dark_blue: (
    background: $dark-blue,
  ),
  Light_blue_clr: (
    background: $Light-blue-clr,
  ),
  light_black: (
    background: $light-black,
  ),
  success_msg_green: (
    background: $success-msg-green,
  ),
);

$body-configurations: (
  body: (
    font-size: 1.4rem,
    line-height: 1.8rem,
    color: $black-color,
    background: $white-color,
    -webkit-font-smoothing: antialiased,
    -moz-osx-font-smoothing: grayscale,
    -ms-font-smoothing: antialiased,
    -webkit-backface-visibility: hidden,
    scrollbar-width: thin,
    /* For FireFox Only */ text-rendering: optimizeLegibility,
  ),
);

$color-configurations: (
  dark_blue: (
    background: $dark-blue,
  ),
  Light_blue_clr: (
    background: $Light-blue-clr,
  ),
  light_black: (
    background: $light-black,
  ),
  success_msg_green: (
    background: $success-msg-green,
  ),
);

$option-dropdown-configurations: (
  option_status: (
    top: -2rem,
    border-radius: 0.4rem,
    border: 1px solid #bdbdbd,
    background: $white-color,
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15),
    padding: 0.8rem 0,
    z-index: 9,
    max-height: 34.5rem,
    overflow: auto,
    overflow-x: hidden,
    position: absolute,
  ),
);

$button-styles: (
  sub_account:(
    background: $form-input-active-bg,
  ),
  upload_img: (
    background: $white-color,
  ),
  btn: (
    color: $primary-color,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 150%,
    background: $white-color,
    padding: 1.2rem 2.4rem,
    border-radius: 0.8rem,
    justify-content: center,
  ),
  primary_btn: (
    min-width: 17.5rem,
    color: $primary-color,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 150%,
    background: $white-color,
    padding: 1.2rem 2.4rem,
    justify-content: center,
  ),
  resend_btn: (
    min-width: 17.5rem,
    color: $white-color,
    background-color: $Light-blue-clr,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 150%,
    padding: 1.2rem 2.4rem,
    gap: 1.2rem,
  ),
  danger_btn: (
    background: $red-required,
    min-width: 17.5rem,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 2.1rem,
    border-radius: 0.8rem,
    padding: 1rem 2rem,
    min-height: 4.8rem,
    gap: 1.2rem,
  ),
  assign_btn: (
    display: flex,
    align-items: center,
    justify-content: center,
    font-weight: 700,
    font-size: 1.6rem,
    line-height: 150%,
    color: $primary-color,
    min-width: 20.9rem,
    min-height: 4.8rem,
    background: transparent,
    gap: 1.2rem,
  ),
  border_btn: (
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 150%,
    min-width: 27.4rem,
    color: $form-lable-color,
    background: $white-color,
    border: 0.2rem solid $Light-blue-clr,
    padding: 1.2rem 2.4rem,
  ),
  unselect_btn: (
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 150%,
    color: $form-lable-color,
    min-width: 20.9rem,
    background: transparent,
    border: 0.2rem solid $primary-color,
  ),
  gray_btn: (
    color: $white-color,
    background: $grey-text,
    border: transparent,
  ),
  blue_btn: (
    color: $white-color,
    background: $Light_blue_clr,
    border: transparent,
  ),
);
