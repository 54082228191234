.messages-graph-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  
    h1 {
      margin-top: 2rem;
      font-size: 3rem;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
  
    .buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      flex-wrap: wrap;
  
      button {
        margin: 0.5rem;
        padding: 0.5rem 1rem;
        background-color: #3b82f6;
        color: white;
        border-radius: 0.25rem;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #2563eb;
        }
      }
    }
  
    .chart-container {
      width: 90%;
      max-width: 1400px;
      aspect-ratio: 16 / 9;
      margin: 2rem auto 0;
      @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: 4 / 3;
      }
    }
  }
  