.w_100 {
  width: 100%;
}
.h_599 {
  height: 599px;
}

.w_50 {
  width: 50%;
}

.w_40 {
  width: 40%;
}

.w_30 {
  width: 30%;
}

.w_25 {
  width: 25%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.fs_10 {
  font-size: 1rem;
}

.fs_12 {
  font-size: 1.2rem;
}

.fs_14 {
  font-size: 1.4rem;
}

.fs_15 {
  font-size: 1.5rem;
}

.fs_16 {
  font-size: 1.6rem;
}

.fs_17 {
  font-size: 1.7rem;
}

.fs_18 {
  font-size: 1.8rem;
}

.fs_20 {
  font-size: 2rem;
}

.fs_24 {
  font-size: 2.4rem;
}

.fs_26 {
  font-size: 2.6rem;
}

.fs_28 {
  font-size: 2.8rem;
}

.fs_30 {
  font-size: 3rem;
}

.fs_32 {
  font-size: 3.2rem;
}

.fs_36 {
  font-size: 3.6rem;
}

.fs_40 {
  font-size: 4rem;
}

.fs_48 {
  font-size: 4.8rem;
}

.fs_206 {
  font-size: 20.6rem;
}

// font Waight
.fw_400 {
  @extend .montserrat_regular;
}

.fw_500 {
  @extend .montserrat_medium;
}

.fw_600 {
  @extend .montserrat_semibold;
}

.fw_700 {
  @extend .montserrat_bold;
}

.trans {
  @include trans;
}

.order_1 {
  order: 1;
}

.order_2 {
  order: 2;
}

// Margin
.m_0 {
  margin: 0;
}

.ml_33 {
  margin-left: 3rem;
}

.ml_0 {
  margin-left: 0;
}

.mr_0 {
  margin-right: 0;
}

.mr_8 {
  margin-right: 0.8rem;
}

.mb_0 {
  margin-bottom: 0;
}

.mt_0 {
  margin-top: 0;
}

.m_auto {
  margin: 0 auto;
}

.ml_10 {
  margin-left: 1rem;
}
.ml_minus_12 {
  margin-left: -1.2rem;
}

.ml_05 {
  margin-left: 0.5rem;
}

.mb_05 {
  margin-bottom: 0.5rem;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_10 {
  margin-bottom: 1rem;
}

.mb_16 {
  margin-bottom: 1.6rem;
}

.mt_16 {
  margin-top: 1.6rem;
}

.mb_20 {
  margin-bottom: 2rem;
}

.mb_30 {
  margin-bottom: 3rem;
}

.mb_24 {
  margin-bottom: 2.4rem;
}

.mt_10 {
  margin-top: 1rem;
}

.mt_25 {
  margin-top: 2.5rem;
}

.mb_12 {
  margin-bottom: 1.2rem;
}

.mb_40 {
  margin-bottom: 4rem;
}

.mb_4 {
  margin-bottom: 0.4rem;
}

.mb_1 {
  margin-bottom: 0.1rem;
}

.mt_4 {
  margin-top: 0.4rem;
}

.mt_10 {
  margin-top: 1rem;
}

.mt_20 {
  margin-top: 2rem;
}

.mt_40 {
  margin-top: 4rem;
}

.mb_8 {
  margin-bottom: 0.8rem;
}

.mt_8 {
  margin-top: 0.8rem;
}

.mb_24 {
  margin-bottom: 2.4rem;
}
.ml_22 {
  margin-left: 2.2rem;
}

.resize_none {
  resize: none;
  width: 100% !important;
}

// Padding
.p_0 {
  padding: 0;
}

.p_20 {
  padding: 0 2rem;
}

.p_40 {
  padding: 4rem;
}
.p_16 {
  padding: 1.6rem;
}

.pl_0 {
  padding-left: 0;
}
.pl_12 {
  padding-left: 1.2rem;
}
.pl_20 {
  padding-left: 2rem;
}

.pl_40 {
  padding-left: 4rem;
}

.pr_0 {
  padding-right: 0;
}

.pr_15 {
  padding-right: 1.5rem;
}

.pb_0 {
  padding-bottom: 0;
}

.pt_0 {
  padding-top: 0;
}

.pt_24 {
  padding-top: 2.4rem;
}

.pb_24 {
  padding-bottom: 2.4rem;
}

.pb_32 {
  padding-bottom: 32px;
}

.pt_32 {
  padding-top: 32px;
}

.pt_10 {
  padding-top: 1rem;
}

.p_auto {
  padding: 0 1.5rem;
}

// Color
.red_error {
  color: $reject_mesg;
}

.green_color {
  color: $success-msg-green;
}

.primary_color {
  color: $primary-color;
}

.l_0 {
  left: 0 !important;
}

.r_0 {
  right: 0 !important;
}

.l_auto {
  left: auto !important;
}

.r_auto {
  right: auto !important;
}

.word_break_word {
  word-break: break-word;
}

// Heading Tags
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .montserrat_regular;
  color: $black-color;
}

p {
  @extend .montserrat_regular;
  color: $black-color;
}

// Positions
.position_fixed {
  @include position_fixed;
}

.position_relative {
  @include position_relative;
}

.position_absolute {
  @include position_absolute;
}

.position_sticky {
  @include position($sticky);
}

// General
.border_0 {
  border: 0 !important;
}

.border_bottom_1 {
  border-bottom: 1px solid $separtor-color;
}

.border_gray {
  border-bottom: 0.1rem solid $border-color;
}

.cursor_pointer,
button {
  cursor: pointer;
}

// Text
.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.text_uppercase {
  text-transform: uppercase;
}

.text_lowercase {
  text-transform: lowercase;
}

.text_capitalize {
  text-transform: capitalize;
}

.text_decoration_none {
  text-decoration: none;
}

.radius_4 {
  border-radius: 0.4rem;
}

.radius_5 {
  border-radius: 0.5rem;
}

.radius_6 {
  border-radius: 0.6rem;
}

.radius_8 {
  border-radius: 0.8rem;
}

.radius_10 {
  border-radius: 1rem;
}

.radius_20 {
  border-radius: 2rem;
}

.radius_30 {
  border-radius: 3rem;
}

.radius_round {
  border-radius: 100%;
}

// Display Properties
.gap_4 {
  gap: 0.4rem;
}

.gap_8 {
  gap: 0.8rem;
}

.gap_10 {
  gap: 1rem;
}

.gap_12 {
  gap: 1.2rem;
}
.gap_20 {
  gap: 2rem;
}
.gap_22 {
  gap: 2.2rem;
}
.gap_24 {
  gap: 2.4rem;
}
.gap_30 {
  gap: 3rem;
}
.gap_40 {
  gap: 4rem;
}
.gap_32 {
  gap: 3.2rem;
}
.d_block {
  display: block;
}

.d_inline_block {
  display: inline-block;
}

.d_none {
  display: none;
}

.d_flex {
  display: flex;
  display: -webkit-flex;
}

.d_grid {
  display: grid;
}

.d_inline_flex {
  display: inline-flex;
  display: -webkit-inline-flex;
}

.d_flex_align {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.align_items_center {
  align-items: center;
}

.align_items_baseline {
  align-items: baseline;
}

.align_items_end {
  align-items: flex-end;
}

.align_items_start {
  align-items: flex-start !important;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_around {
  justify-content: space-around;
}

.justify_content_evenly {
  justify-content: space-evenly;
}

.justify_content_start {
  justify-content: flex-start;
}

.justify_content_end {
  justify-content: flex-end;
}

.d_flex_center {
  @extend .d_flex;
  @extend .align_items_center;
  @extend .justify_content_center;
  display: -webkit-flex;
}

.d_flex_between {
  @extend .d_flex;
  @extend .align_items_center;
  @extend .justify_content_between;
}

.flex_direction_column {
  flex-direction: column;
}

.flex_direction_column_reverse {
  flex-direction: column-reverse;
}
.flex_direction_row{
  flex-direction: row;
}
.flex_direction_row_reverse {
  flex-direction: row-reverse;
}

.nowrap {
  flex-wrap: nowrap;
}

.flex_wrap {
  flex-wrap: wrap;
}

.word_break {
  word-break: break-all;
}

.white_space_nowrap {
  white-space: nowrap;
}

.white_space_wrap {
  white-space: normal;
}

.text_ellipsis {
  text-overflow: ellipsis;
}

.over_hidden {
  overflow: hidden;
}

.overflow_auto {
  overflow: auto;
}

.hover_transition {
  transition: all 0.8s ease-in-out;
}

.tooltip {
  @include tooltip();
  z-index: 999;
}

.full_username {
  @include full_username(
    (
      background: $dark-bg,
      color: $white-color,
    )
  );
  z-index: 9999 !important;
}

.w_27 {
  width: 27%;
}
.h_100 {
  height: 100%;
}

.transform_translate {
  transform: translate(-50%, -50%);
}

// Form
.form_group {
  @extend .position_relative;
  margin-bottom: 3.2rem;

  label {
    @extend .fs_16;
    @extend .fw_700;
    @extend .d_block;
    @extend .text_left;
    line-height: 2.1rem;
    margin-bottom: 0.8rem;
  }

  .form_control {
    @extend .fs_16;
    @extend .fw_400;
    @extend .radius_4;
    min-height: 5.5rem;
    @extend .w_100;
    padding: 0.8rem 1.5rem;
    border: 0.1rem solid $form-input-border;

    &.blue_border {
      border: 0.2rem solid $lighten-dark-blue !important;
    }

    &.focus_border:focus {
      border: 0.1rem solid $lighten-dark-blue;
    }

    &.border_red {
      border: 0.1rem solid #891010;
    }
    &.border_green {
      border: 0.1rem solid $success-msg-green;
    }

    &.not_editable {
      border: 0.1rem solid #b6d4f8;
      background: $form-input-active-bg;
    }
    &.downArrow {
      background: url(../images/filter_drop_down_arrow.svg) no-repeat center
        right 16px $white-color;
    }
    &.upArrow {
      background: url(../images/filter_drop_down_up_arrow.svg) no-repeat center
        right 1.6rem $white-color !important;
    }
  }

  // .options_status_custom {
  //   @include apply_optionStatus(option_status,
  //     $additional-properties: (width: 100%)
  //   );
  // }

  .select_region {
    .form_control {
      @extend .fs_16;
      @extend .fw_400;
      @extend .radius_4;
      min-height: 4rem;
      width: 100%;
      padding: 0.8rem 1.5rem;
      border: 0.1rem solid $separtor-color;
      background: url(../images/filter_drop_down_arrow.svg) no-repeat center
        right 16px $white-color;
      padding-right: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &::placeholder {
        opacity: 60%;
        color: $form-input-color;
      }

      &.active {
        background: url(../images/filter_drop_down_up_arrow.svg) no-repeat
          center right 1.6rem $white-color !important;
      }

      &.blue_border {
        // border: 0.1rem solid $lighten-dark-blue;
        border-color: $blue-color;
        // box-shadow: 0 0 0 0.1rem $blue-color !important;
        min-height: 4rem;
      }
    }
  }

  // .css-1hb7zxy-IndicatorsContainer {
  //   opacity: 0;
  // }
  .options_status {
    width: 100%;
    @extend .position_absolute;
    top: 7rem;
    // bottom: 0;
    @extend .radius_4;
    border: 1px solid #bdbdbd;
    background: $white-color;
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
    padding: 0.8rem 0;
    z-index: 9;
    max-height: 34.5rem;
    overflow: auto;
    overflow-x: hidden;

    .all_location {
      @extend .align_items_center;
      @extend .cursor_pointer;
      // background: #e1ebf9;
      padding: 8px 16px;
      min-height: 5.5rem;
      margin-bottom: 0.1rem;

      &.active {
        background: #b6d4f8;
      }

      .list_status {
        @extend .d_flex_center;
        gap: 1.6rem;

        .select_tab {
          padding-left: 1rem;

          .region_tab {
            color: $form-lable-color;
            @extend .fs_16;
            @extend .fw_500;
            // line-height: 140%;
            line-height: 2.3rem;
            padding: 0;
            // display: contents;
            &.selected {
              @extend .fw_700;
            }
            &.status_tab {
              @extend .d_flex;
              @extend .align_items_center;
            }
          }
        }
      }
    }

    .search_region {
      padding: 0 0.8rem;
      @include position_relative;
      border-bottom: 1px solid $border-color;

      .serch_icon {
        width: 2.4rem;
        height: 2.4rem;
        @extend .d_flex_center;
        @extend .cursor_pointer;
        @extend .position_absolute;
        top: 0.8rem;
        left: 1.8rem;
      }

      .serch_icon_remove {
        width: 2.4rem;
        height: 2.4rem;
        @extend .d_flex_center;
        @extend .cursor_pointer;
        @extend .position_absolute;
        top: 0.8rem;
        right: 1.8rem;
      }

      .search_tab {
        border-radius: 100px;
        border: 1px solid $border-color;
        background: $white-color;
        margin-bottom: 1rem;
        padding-left: 4.2rem;

        &.blue_border {
          border: 0.1rem solid $lighten-dark-blue;
        }
      }
    }
  }

  .input_fild_remove {
    @extend .position_absolute;
    top: 3.7rem;
    right: 1.6rem;
    @extend .cursor_pointer;
    background: $white-color;
    padding-left: 0.3rem;
    z-index: 9;

    &.info_remove {
      background: transparent;

      img {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &.white_bg {
      background: $white-color;
    }
    &.not_edit_bg {
      background: $form-input-active-bg;
    }
  }

  .go_back {
    color: $dark-blue;
    @extend .inter_bold;
    @extend .fs_16;
    @extend .fw_700;
    line-height: 22.4px;
    margin: 2rem 0;
    @extend .text_center;
    @extend .d_flex_center;
  }

  .pass_requrd {
    margin-top: 1.6rem;

    .rules {
      color: $black-color;
      @extend .fs_14;
      @extend .fw_400;
      line-height: 21px;
    }

    ul {
      padding-left: 2.4rem;

      li {
        list-style: disc;
        color: $light-gray;
        @extend .fs_14;
        @extend .fw_400;
        line-height: 2.1rem;
      }
    }
  }
  .add_plus_icon {
    @extend .cursor_pointer;
    @extend .d_block;
    width: 3.2rem;
    height: 3.2rem;
    @include position_absolute;
    top: 34px;
    right: 1.6rem;
    // @extend .d_none;
    &:hover {
      .tooltip {
        @extend .d_block;
        top: -4rem;
      }
    }
  }
  &.statue_tabing {
    .options_status {
      .all_location {
        .list_status {
          .select_tab {
            .region_tab {
              padding: 0.5rem 1.2rem;
            }
          }
        }
      }
    }
  }
}

// Unorderded List : UL/LI
.list_unstyled {
  list-style: none;
}

// Image
.img_object_center {
  object-fit: cover;
  object-position: center;
}
img {
  @extend .img_object_center;
}
.img_fluid {
  max-width: 100%;
}

//error
.error_msg {
  input,
  textarea,
  .edit_assign_school_info,
  .phone_number {
    border: 0.2rem solid $error-msg !important;
    margin-bottom: 4px;
    &.blue_border {
      border: 0.1rem solid $lighten-dark-blue;
    }
  }
}

.error_msg {
  .phone_number {
    input {
      border: none !important;
    }
  }
}

.show_error_msg {
  color: $red-required;
  @extend .fs_14;
  @extend .fw_500;
  line-height: 1.8rem;
  margin-top: 0.5rem;
  @extend .text_left;
  @extend .d_block;

  &.emai_error_msg {
    @extend .d_block;
    @extend .text_right;
    font-style: italic;
  }

  &.add_partner_modal_error {
    @extend .d_block;
    @extend .text_left;
  }
}

.blue_text {
  margin-left: 1.2rem;
  color: $primary-color;
  @extend .fw_700;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.status_active {
  background-color: $success-clr-bg;
}

.status_pending {
  background-color: $grey-100;
}

.check_box {
  @extend .position_relative;
  height: 1.6rem;

  .custom_check {
    @include position_absolute;
    z-index: 999;
    width: 16px;
    height: 16px;
    opacity: 0;
    @extend .cursor_pointer;

    &:checked ~ .checkmark {
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }
  }

  .checkmark {
    @include position_absolute;
    top: 0px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 2.667px;
    border: 1.333px solid $tab-text;
    background: $white-color;
    &::after {
      content: "";
      @extend .d_block;
      width: 1rem;
      height: 0.5rem;
      border: 2px solid $white-color;
      border-top: 0;
      border-right: 0;
      transform: rotate(-56deg);
      @include position_absolute;
      left: 0.2rem;
      top: 3.5px;
    }
  }
}

.border_sky {
  .form_control {
    border: 0.1rem solid $primary-color;
  }
}

.unauthoriz_page {
  @extend .position_relative;

  &::after {
    content: "";
    @extend .d_block;
    @extend .w_100;
    height: 54rem;
    max-width: 54rem;
    background: url(../images/unauthoraiz_bg.png) no-repeat;
    @extend .position_absolute;
    right: 0;
    bottom: 0;
  }

  .unautho_content {
    width: 100%;
    height: 100vh;
    max-width: 67rem;
    @extend .m_auto;
    @extend .d_flex_center;
    @extend .flex_direction_column;

    h1 {
      color: $tab-head;
      @extend .fs_206;
      @extend .fw_500;
      @extend .d_flex_center;
      margin-bottom: 3.2rem;
    }
    span{
      margin: 0 -2.5rem ;
    }

    h2 {
      color: $tab-head;
      @extend .fs_48;
      @extend .fw_700;
      line-height: normal;
      max-width: 66.7rem;
      @extend .m_auto;
      @extend .text_center;
      margin-bottom: 1.6rem;
    }

    .content {
      color: #8f8f8f;
      @extend .fs_24;
      @extend .fw_400;
      @extend .text_center;
      line-height: 36px;
      margin-bottom: 5.6rem;
    }

    .unauth_btn {
      .primary_btn {
        padding: 2rem 7rem;
        min-width: 29.1rem;
        min-height: 6.8rem;
      }
    }
  }
  &.unexcepted_error {
    .unautho_content {
      max-width: 93rem;
      .unexcepted_error {
        margin-bottom: 6.4rem;
      }
      h2 {
        max-width: 100%;
      }
      .try_again {
        @extend .fs_24;
        @extend .fw_600;
        @extend .text_center;
        line-height: 36px;
        margin-bottom: 2.4rem;
        color: $tab-text;
        @extend .text_center;
      }
      .blue_border_btn {
        min-width: 24.9rem;
        padding: 1.8rem 2.4rem;
      }
    }
  }
}

.msg_border_icon {
  width: 4rem;
  height: 4rem;
  border: 0.1rem solid $border-color;
  border-radius: 5rem;
  @extend .grid;
  place-items: center;
  @extend .cursor_pointer;
  background-color: $white-color;
  @include trans;
  img {
    width: 2.1rem;
    height: 2.1rem;
  }
  &:hover {
    border-color: $primary-color;
  }
}

.status_tab {
  @extend .fs_16;
  @extend .fw_400;
  color: $tab-head;
  padding: 0.5rem 1.2rem;
  min-height: 3rem;
  background-color: transparent;
  @extend .radius_6;
  vertical-align: middle;
  @extend .d_flex;
  @extend .align_items_center;

  &.active {
    background-color: $success-clr-bg;
  }
  &.pending {
    background-color: $grey-100;
  }
  &.inprogress {
    background-color: $light-clr-yellow;
  }
  &.event_disabled {
    background-color: $grey-100;
  }
  &.completed {
    background-color: $light-clr-sky;
  }
  &.notstarted {
    background-color: #fbe7e9;
  }
  &.matching_inprogress {
    background-color: #f7dcb4;
  }
  &.matching {
    background-color: #d1f3f1;
  }
  &.matched {
    background-color: $success-clr-bg;
    &.matched_not_registered {
      max-width: 16rem;
    }
  }
  &.matched_not_registered {
    max-width: 13rem;
  }
  &.invited {
    background-color: $grey-100;
  }
  &.archived {
    background-color: $border-color;
  }
  &.draft {
    background-color: $white-color;
    color: $tab-text;
  }
  &.declined {
    background-color: #ea332333;
    color: $black-color;
  }
  &.invited_border {
    border-color: #d9d9d9 !important;
  }
  &.completed_border {
    border-color: #9aa7c7 !important;
  }
  &.matched_border {
    border-color: $success-clr-bg !important;
  }
}

.i_popup {
  @include i_popup(
    (
      height: 100%,
      width: 100%,
    )
  );
  @include apply_animation(popup_animate);
}

.modal_common {
  @include modal_common;
}

.modal_common_after {
  @include modal_common_after;
}

.i_popup_content {
  @include i_popup_content;
}

.common-head-li {
  @extend .montserrat_medium;
  @include common-head-li;
}

.common-head-li-active {
  @extend .montserrat_bold;
  @include common-head-li(
    (
      color: $dark-blue,
      background: $form-input-active-bg,
      border-bottom: 0.4rem solid $primary-color,
    )
  );
}

.header_notification {
  @include shape-styles(
    (
      border-color: $border-color,
    )
  );
}

.table-Styles {
  @include table-styles(
    $custom-styles: (
      background: $white-clr-transparent,
    )
  );
}

.pairs_user_listing_width {
  @include table-styles(
    $custom-styles: (
      min-width: 160rem,
      background: transparent,
    )
  );
}

.mentees_table_width {
  @include table-styles(
    $custom-styles: (
      min-width: 150rem,
      background: transparent,
    )
  );
}

.back_btn {
  @extend .d_flex_align;
  align-items: self-start;
  color: $black-color;
  @extend .fs_18;
  @extend .fw_500;
  line-height: 140%;
  padding-left: 4.5rem;
  .back_text {
    @extend .fw_700;
    margin-left: 0.5rem;
  }
  .back_icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 2rem;
    @extend .d_flex_center;
    @extend .cursor_pointer;
  }
  &.return_page {
    padding-left: 0;
  }
}
.text_over_ellip {
  max-width: 12rem !important;
  @extend .text_ellipsis;
  @extend .over_hidden;
  display: block
}
.text_over_ellip_limited {
  @extend .text_ellipsis;
  @extend .over_hidden;
  display: block;
  max-width: 14rem !important;
}

.all_tooltip_according {
  @include full_username;
}

.text_over_reason_mentor {
  max-width: 100%;
  @extend .text_ellipsis;
  @extend .over_hidden;
  @extend .d_block;
  &:hover {
    + .tooltip {
      @extend .d_block;
    }
  }
}
